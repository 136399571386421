<template>
	<div>
		<div class="d-flex justify-space-between align-center mb-2 mt-2">
			<div class="input-wrap">
				<h5 class="mb-2">Reporte por motivos de viaje</h5>
			</div>
			<div class="action-btn-wrap">
				<!-- <v-btn v-if="records.length > 0" color="accent" @click="clickDownloadExcel()">
					<v-icon left small>mdi-file-excel</v-icon> Exportar Excel
				</v-btn> -->
			</div>
		</div>
		<v-card color="secondary" class="pa-3">
			<v-row dense>
				<v-col cols="12" xl="3" lg="4" md="12" sm="12">
					<div class="form-group">
						<label class="control-label">Desde</label>
						<el-date-picker
							v-model="search.start"
							type="date"
							value-format="yyyy-MM-dd"
							format="dd/MM/yyyy"
							:picker-options="pickerOptions"
						></el-date-picker>
					</div>
				</v-col>
				<v-col cols="12" xl="3" lg="4" md="12" sm="12">
					<div class="form-group">
						<label class="control-label">Hasta</label>
						<el-date-picker
							v-model="search.end"
							type="date"
							value-format="yyyy-MM-dd"
							format="dd/MM/yyyy"
							:picker-options="pickerOptionsDates"
						></el-date-picker>
					</div>
				</v-col>
				<v-col cols="12" xl="2" lg="4" md="12" sm="12">
					<el-button
						type="primary"
						icon="el-icon-search"
						class="btn-block mt-5"
						:loading="loading"
						@click="getData"
					></el-button>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="12" xl="12" lg="12">
					<v-simple-table dense>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-right">
                                        N°
                                    </th>
                                    <th class="text-left">
                                        DESCRIPCIÓN
                                    </th>
                                    <th class="text-right">
                                        CANTIDAD
                                    </th>
                                    <th class="text-right">
                                        %
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in records" :key="index">
                                    <td class="text-right">{{ index + 1 }}</td>
                                    <td>{{ item.name }}</td>
                                    <td class="text-right">{{ item.quantity }}</td>
                                    <td class="text-right">{{ item.percentage }} %</td>
                                </tr>
                            </tbody>
                            <tfoot>
								<tr>
									<td colspan="2"></td>
									<td class="text-right"><strong class="font-black title">Total</strong></td>
									<td class="text-right title">
										<strong>{{ total }}</strong>
									</td>
								</tr>
							</tfoot>
                        </template>
                    </v-simple-table>
				</v-col>
			</v-row>
		</v-card>
	</div>
</template>

<script>
import dayjs from 'dayjs';
export default {
	data() {
		return {
			resource: 'reports/reasons-travel',
			records: [],
			loading: false,
			total: 0,
			search: {
				start: dayjs()
					.startOf('month')
					.format('YYYY-MM-DD'),
				end: dayjs().format('YYYY-MM-DD'),
			},
			pickerOptions: {
				disabledDate: (time) => {
					time = dayjs(time).format('YYYY-MM-DD');
					return dayjs().format('YYYY-MM-DD') < time;
				},
			},
			pickerOptionsDates: {
				disabledDate: (time) => {
					time = dayjs(time).format('YYYY-MM-DD');
					return this.search.start > time;
				},
			},
		};
	},
	created() {
		this.getData();
	},
	methods: {
		async getData() {
			this.loading = true;
			await this.$http.post(`/${this.resource}/records`, this.search).then((response) => {
				this.records = response.data.records;
				this.total = response.data.total;

				this.loading = false;
			});
		},
	},
};
</script>
